import addresses from 'config/constants/contracts'

import { GRAPH_API_PREDICTION_CAKE, GRAPH_API_PREDICTION_BNB } from 'config/constants/endpoints'
import { getAddress } from 'utils/addressHelpers'
import { bscTokens, CAKE_FTM, CAKE_OP } from '@pancakeswap/tokens'
import { BigNumber } from '@ethersproject/bignumber'

const DEFAULT_MIN_PRICE_USD_DISPLAYED = BigNumber.from(10000)

const MAIN_CHAIN = 250

export default {
  BNB: {
    address: getAddress(addresses.predictionsBNB, MAIN_CHAIN),
    api: GRAPH_API_PREDICTION_BNB,
    chainlinkOracleAddress: getAddress(addresses.chainlinkOracleBNB, MAIN_CHAIN),
    minPriceUsdDisplayed: DEFAULT_MIN_PRICE_USD_DISPLAYED,
    displayedDecimals: 4,
    token: bscTokens.bnb,
  },
  CAKE: {
    address: getAddress(addresses.predictionsCAKE, MAIN_CHAIN),
    api: GRAPH_API_PREDICTION_CAKE,
    chainlinkOracleAddress: getAddress(addresses.chainlinkOracleCAKE, MAIN_CHAIN),
    minPriceUsdDisplayed: DEFAULT_MIN_PRICE_USD_DISPLAYED,
    displayedDecimals: 4,
    token: CAKE_FTM,
  },
  // CAKE: {
  //   address: getAddress(addresses.predictionsCAKE, MAIN_CHAIN),
  //   api: GRAPH_API_PREDICTION_CAKE,
  //   chainlinkOracleAddress: getAddress(addresses.chainlinkOracleCAKE, MAIN_CHAIN),
  //   minPriceUsdDisplayed: DEFAULT_MIN_PRICE_USD_DISPLAYED,
  //   displayedDecimals: 4,
  //   token: CAKE_OP,
  // },
}
